import { requestData, receiveData } from '../api/actions';
import {
    getOrderList,
    getQuestionList,
    postAnswer,
    getMergedFile,
    getFile,
    saveGridlyAccess,
    getGridlyAccessToken,
    getGridlyDeliveredGrids,
    postOrderToGridly
} from 'services/customer';
import { groupBy } from 'lodash';

import { CUSTOMER } from './actionConstants';
import { GRIDLY_OAUTH_URL } from 'common/const';

export function closeMessage() {
    return {
        type: CUSTOMER.CLOSE_MESSAGE
    };
}

export function setMinIntegrationBox(value) {
    return {
        type: CUSTOMER.MIN_INTEGRATION_BOX,
        data: value
    };
}

export function setDoneOAuthFlow(value) {
    return {
        type: CUSTOMER.DONE_OAUTH_FLOW,
        data: value
    };
}

export function fetchOrderList({ offset = 0, limit = 50 }) {
    return dispatch => {
        dispatch(requestData(CUSTOMER.FETCH_ORDER_LIST));

        getOrderList({ offset, limit })
            .then(payload => {
                dispatch(
                    receiveData(CUSTOMER.ON_ORDER_LIST_FETCHED, payload.data)
                );
            })
            .catch(e => {
                dispatch(receiveData(CUSTOMER.ON_ORDER_LIST_FAILED, e));
            });
    };
}

function formatQuestions(data) {
    return groupBy(data, 'ctx.sourceText');
}

export function fetchQuestionList(orderId) {
    return dispatch => {
        dispatch(requestData(CUSTOMER.FETCH_QUESTION_LIST));

        getQuestionList(orderId)
            .then(payload => {
                const data = formatQuestions(payload.data || []);
                dispatch(receiveData(CUSTOMER.ON_QUESTION_LIST_FETCHED, data));
            })
            .catch(e => {
                dispatch(receiveData(CUSTOMER.ON_QUESTION_LIST_FAILED, e));
            });
    };
}

export function fetchAnswerList(sourceId) {
    return dispatch => {
        dispatch(requestData(CUSTOMER.FETCH_ANSWER_LIST));

        getQuestionList(sourceId)
            .then(payload => {
                dispatch(
                    receiveData(CUSTOMER.ON_ANSWER_LIST_FETCHED, payload.data)
                );
            })
            .catch(e => {
                dispatch(receiveData(CUSTOMER.ON_ANSWER_LIST_FAILED, e));
            });
    };
}

export function addAnswer({ questionId, content, sourceText, callback }) {
    return dispatch => {
        dispatch(requestData(CUSTOMER.ADD_ANSWER));

        postAnswer(questionId, content)
            .then(payload => {
                const { data } = payload;
                dispatch(
                    receiveData(CUSTOMER.ON_ANSWER_ADDED, {
                        ...data,
                        sourceText
                    })
                );
                callback && callback();
            })
            .catch(e => {
                dispatch(receiveData(CUSTOMER.ON_ADD_ANSWER_FAILED, e));
                callback && callback(e);
            });
    };
}

export function downloadMergedFile({ orderId, fileName }) {
    return () => {
        getMergedFile(orderId, fileName)
            .then(payload => {
                openDownloadedFile(fileName, payload);
            })
            .catch(e => {
                console.log(e);
            });
    };
}

function openDownloadedFile(fileName, payload) {
    let a = document.createElement('a');
    a.style = 'display: none';
    const blob = new Blob([payload], {
        type: 'application/octet-stream'
    });

    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    setTimeout(function() {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }, 100);
}

export function downloadFile({ orderId, fileName }) {
    return () => {
        getFile(orderId, fileName)
            .then(payload => {
                openDownloadedFile(fileName, payload);
            })
            .catch(e => {
                console.log(e);
            });
    };
}

export function addGridlyAccount({ code, successCallback }) {
    return dispatch => {
        dispatch(requestData(CUSTOMER.SYNC_GRIDLY_ACCOUNT));

        const redirectUri = getGridlyOAuthRedirectUri();

        saveGridlyAccess(code, redirectUri)
            .then(payload => {
                const access = payload.data || {};

                if (!access.projectId) {
                    _fetchGridlyAccess(dispatch, 1);
                }

                dispatch(
                    receiveData(
                        CUSTOMER.ON_SYNC_GRIDLY_ACCOUNT_SUCCESS,
                        payload.data
                    )
                );
                successCallback && successCallback();
            })
            .catch(e => {
                dispatch(
                    receiveData(CUSTOMER.ON_SYNC_GRIDLY_ACCOUNT_FAILED, e)
                );
                successCallback && successCallback();
            });
    };
}

function _fetchGridlyAccess(dispatch, count = 0) {
    dispatch(requestData(CUSTOMER.GET_GRIDLY_ACCESS));

    getGridlyAccessToken()
        .then(payload => {
            const access = payload.data || {};

            if (!access.projectId && count < 10) {
                setTimeout(() => {
                    _fetchGridlyAccess(dispatch, count + 1);
                }, 1000); // Max wait 9s for deliverToGridly job in the background
            }

            dispatch(
                receiveData(CUSTOMER.ON_GET_GRIDLY_ACCESS_SUCCESS, payload.data)
            );
        })
        .catch(e => {
            dispatch(receiveData(CUSTOMER.ON_GET_GRIDLY_ACCESS_FAILED, e));
        });
}

export function fetchGridlyAccess() {
    return dispatch => {
        _fetchGridlyAccess(dispatch, 0);
    };
}

function _fetchGridlyDeliveredGrids(dispatch, count = 0) {
    dispatch(requestData(CUSTOMER.GET_GRIDLY_DELIVERED_GRIDS));

    getGridlyDeliveredGrids()
        .then(payload => {
            const data = payload.data || [];
            const needRetry = data.find(delivered => {
                if (!delivered.viewId) {
                    return true;
                }

                return false;
            });

            if (needRetry && count < 10) {
                setTimeout(() => {
                    _fetchGridlyDeliveredGrids(dispatch, count + 1);
                }, 1000); // Max wait 9s for deliverToGridly job in the background
            }

            dispatch(
                receiveData(
                    CUSTOMER.ON_GET_GRIDLY_DELIVERED_GRIDS_SUCCESS,
                    data
                )
            );
        })
        .catch(e => {
            dispatch(
                receiveData(CUSTOMER.ON_GET_GRIDLY_DELIVERED_GRIDS_FAILED, e)
            );
        });
}

export function fetchGridlyDeliveredGrids() {
    return dispatch => {
        _fetchGridlyDeliveredGrids(dispatch);
    };
}

export function deliverToGridly(order) {
    return dispatch => {
        dispatch(requestData(CUSTOMER.DELIVER_GRIDLY_ORDER));

        postOrderToGridly(order)
            .then(payload => {
                dispatch(
                    receiveData(
                        CUSTOMER.ON_DELIVER_GRIDLY_ORDER_SUCCESS,
                        payload.data
                    )
                );

                _fetchGridlyDeliveredGrids(dispatch);
            })
            .catch(e => {
                dispatch(
                    receiveData(CUSTOMER.ON_DELIVER_GRIDLY_ORDER_FAILED, e)
                );
            });
    };
}

const getGridlyOAuthRedirectUri = () => {
    return `${window.location.protocol}//${window.location.host}/orders?flow_name=GeneralOAuthFlow`;
};

export function gotoGridlyOAuthPage() {
    window.location.href = `${GRIDLY_OAUTH_URL}${encodeURI(
        `/oauth/authorize?grant_type=authorization_code&response_type=code&client_id=order_local&redirect_uri=${getGridlyOAuthRedirectUri()}`
    )}`;
}
