export const CUSTOMER = {
    CLOSE_MESSAGE: 'CUSTOMER_CLOSE_MESSAGE',
    MIN_INTEGRATION_BOX: 'MIN_INTEGRATION_BOX',
    DONE_OAUTH_FLOW: 'DONE_OAUTH_FLOW',
    FETCH_ORDER_LIST: 'CUSTOMER_FETCH_ORDER_LIST',
    ON_ORDER_LIST_FETCHED: 'CUSTOMER_ON_ORDER_LIST_FETCHED',
    ON_ORDER_LIST_FAILED: 'CUSTOMER_ON_ORDER_LIST_FAILED',
    FETCH_QUESTION_LIST: 'CUSTOMER_FETCH_QUESTION_LIST',
    ON_QUESTION_LIST_FETCHED: 'CUSTOMER_ON_QUESTION_LIST_FETCHED',
    ON_QUESTION_LIST_FAILED: 'CUSTOMER_ON_QUESTION_LIST_FAILED',
    FETCH_ANSWER_LIST: 'CUSTOMER_FETCH_ANSWER_LIST',
    ON_ANSWER_LIST_FETCHED: 'CUSTOMER_ON_ANSWER_LIST_FETCHED',
    ON_ANSWER_LIST_FAILED: 'CUSTOMER_ON_ANSWER_LIST_FAILED',
    ADD_ANSWER: 'CUSTOMER_ADD_ANSWER',
    ON_ANSWER_ADDED: 'CUSTOMER_ON_ANSWER_ADDED',
    ON_ADD_ANSWER_FAILED: 'CUSTOMER_ON_ADD_ANSWER_FAILED',
    SYNC_GRIDLY_ACCOUNT: 'SYNC_GRIDLY_ACCOUNT',
    ON_SYNC_GRIDLY_ACCOUNT_SUCCESS: 'ON_SYNC_GRIDLY_ACCOUNT_SUCCESS',
    ON_SYNC_GRIDLY_ACCOUNT_FAILED: 'ON_SYNC_GRIDLY_ACCOUNT_FAILED',
    GET_GRIDLY_ACCESS: 'GET_GRIDLY_ACCESS',
    ON_GET_GRIDLY_ACCESS_SUCCESS: 'ON_GET_GRIDLY_ACCESS_SUCCESS',
    ON_GET_GRIDLY_ACCESS_FAILED: 'ON_GET_GRIDLY_ACCESS_FAILED',
    GET_GRIDLY_DELIVERED_GRIDS: 'GET_GRIDLY_DELIVERED_GRIDS',
    ON_GET_GRIDLY_DELIVERED_GRIDS_SUCCESS:
        'ON_GET_GRIDLY_DELIVERED_GRIDS_SUCCESS',
    ON_GET_GRIDLY_DELIVERED_GRIDS_FAILED:
        'ON_GET_GRIDLY_DELIVERED_GRIDS_FAILED',
    DELIVER_GRIDLY_ORDER: 'DELIVER_GRIDLY_ORDER',
    ON_DELIVER_GRIDLY_ORDER_SUCCESS: 'ON_DELIVER_GRIDLY_ORDER_SUCCESS',
    ON_DELIVER_GRIDLY_ORDER_FAILED: 'ON_DELIVER_GRIDLY_ORDER_FAILED'
};
