import React from 'react';
import { Link } from 'react-router-dom';
import './QnACell.css';

const QnACell = ({ orderId, length }) => {
    return (
        <Link className="link-btn qa-btn qnacell" to={`/orders/${orderId}`}>
            <i className="far fa-comments" title={'Open Q&A'} />
            {length > 0 && <span className="indicator" />}
        </Link>
    );
};

export default QnACell;
