import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import Yup from 'yup';
import { TextFieldInput, Message, toJS } from 'components';
import { signIn } from 'services/auth';
import { getMessage } from 'common/getMessage';
import { notifyToSlack } from 'common/gtmScripts';
import { onLogInSuccess, onForgotPasswordOpen } from './actions';
import Cookies from 'js-cookie';

//import { AUTHED_PAGE_HOME } from 'common/const';

const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        password: Yup.string()
            .required('Password is required')
            .min(8, 'Password length must be at least 8')
    }),

    mapPropsToValues: ({ user }) => ({
        ...user
    }),
    handleSubmit: (payload, { setSubmitting, setStatus, props }) => {
        setStatus({ error: null });
        signIn(payload)
            .then(data => {
                Cookies.set('token', data.accessToken, {
                    expires: 365
                });
                props.onLogInSuccess(data.user);
                setSubmitting(false);
                //setStatus({ redirectTo: AUTHED_PAGE_HOME });
            })
            .catch(e => {
                setStatus({
                    error: getMessage({
                        token: e.message,
                        httpCode: e.code || e.status
                    })
                });
                setSubmitting(false);
                notifyToSlack({
                    userEmail: payload.email,
                    status: 'failed',
                    stepName: 'Log in',
                    errorCode: e.message
                });
            });
    },
    displayName: 'SignInForm'
});

export const SignInForm = props => {
    const {
        values,
        touched,
        errors,
        isValid,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        status,
        header,
        onForgotPasswordOpen
    } = props;

    const message = status && status.error && (
        <Message type="danger" message={status.error} />
    );
    const redirect =
        status && status.redirectTo ? (
            <Redirect to={status.redirectTo} />
        ) : null;

    return (
        <div className="sign-in-form">
            {message}
            {redirect}
            {!!header && header}
            <form id="sign-in-form" onSubmit={handleSubmit}>
                <TextFieldInput
                    id="email"
                    type="email"
                    label="Email"
                    className="input-line"
                    placeholder=""
                    error={touched.email && errors.email}
                    value={values.email || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <div className="password-wrapper">
                    <TextFieldInput
                        id="password"
                        type="password"
                        label="Password"
                        className="input-line"
                        placeholder=""
                        error={touched.password && errors.password}
                        value={values.password || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="off"
                    />
                    <span
                        className="forgot-btn text-btn clickable"
                        onClick={onForgotPasswordOpen}
                    >
                        Forgot your password?
                    </span>
                </div>
                <div className="submit-btns">
                    <button
                        type="submit"
                        className={`btn btn-login ${
                            isSubmitting ? 'loading' : ''
                        }`}
                        disabled={isSubmitting || !isValid}
                    >
                        Log in
                    </button>
                </div>
            </form>
        </div>
    );
};

const EnhancedForm = formikEnhancer(SignInForm);

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.getIn(['auth', 'user'])
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            onLogInSuccess,
            onForgotPasswordOpen
        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(toJS(EnhancedForm));
