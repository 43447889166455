import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setMinIntegrationBox } from './actions';

import './GridlyIntegrator-style.css';

class GridlyIntegrated extends React.PureComponent {
    onClick = () => {
        const { setMinIntegrationBox } = this.props;

        setMinIntegrationBox(false);
    };
    render() {
        const { gridlyAccess, isMinIntegrationBox } = this.props;

        return (
            !gridlyAccess &&
            isMinIntegrationBox && (
                <div
                    className="gridly-integrated-box clickable"
                    onClick={this.onClick}
                >
                    <span>
                        <img
                            className="logo"
                            title="Gridly"
                            alt="logo"
                            src="/img/logo_gridly.svg"
                        />
                    </span>
                    <span className="text">
                        {'Headless CMS for Multilingual Game Development '}
                    </span>
                    <span>
                        <img
                            className="image"
                            title="Gridly-person"
                            alt="logo"
                            src="/img/gridly-int-person.svg"
                        />
                    </span>
                </div>
            )
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        isMinIntegrationBox: state.getIn([
            'customer',
            'gridly',
            'isMinIntegrationBox'
        ]),
        gridlyAccess: state.getIn(['customer', 'gridly', 'access', 'data'])
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            setMinIntegrationBox
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(GridlyIntegrated);
