import React from 'react';
import { PAGES } from 'common/const';
import { Navbar } from 'components';
import { UserNav } from 'auth';

import './AppHeader-style.css';

/*const NavGroups = ({ current, pages, group, onItemClick }) => {
    return pages.filter(p => p.group === group).map(({ path, title }) => {
        const isActive = path === current;
        return (
            <NavItem
                key={path}
                active={isActive}
                path={path}
                title={title}
                onClick={onItemClick}
            />
        );
    });
};
*/

export class AppHeader extends React.PureComponent {
    state = {
        expanded: false
    };
    render() {
        //const { current, pages } = this.props;
        //const { expanded } = this.state;

        return (
            <div className="app-header header container-full">
                <Navbar classNames="navbar-light sticky-top">
                    <a href={PAGES.LANDING} className="navbar-brand">
                        <img
                            className="logo"
                            title="Home"
                            alt="logo"
                            src="/img/logo_ld.svg"
                        />
                    </a>
                    {/*
                    <Navbar.Toggle
                        target="navbar-collapse-container"
                        onToggle={this.handleMenuToggle}
                        expanded={expanded}
                    />
                    <Navbar.Collapse
                        targetId="navbar-collapse-container"
                        expanded={expanded}
                    >
                        <Nav classNames="navbar-nav mr-auto">
                            <NavGroups
                                current={current}
                                pages={pages}
                                group="center"
                                onItemClick={this.handleMenuToggle}
                            />
                        </Nav>
                        <Nav className="navbar-nav mr-auto">
                            <NavGroups
                                current={current}
                                pages={pages}
                                group="right"
                                onItemClick={this.handleMenuToggle}
                            />
                        </Nav>
                    </Navbar.Collapse>
                    */}
                    <span className="auto-left auto-center">
                        <UserNav />
                    </span>
                </Navbar>
            </div>
        );
    }
    handleMenuToggle = e => {
        this.setState({ expanded: !this.state.expanded });
    };
}
