import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
    addGridlyAccount,
    fetchGridlyAccess,
    fetchGridlyDeliveredGrids,
    gotoGridlyOAuthPage,
    setMinIntegrationBox,
    setDoneOAuthFlow
} from './actions';
import { queryParamsToObject } from 'common/urlQueryUtils';

import './GridlyIntegrator-style.css';

class GridlyIntegrator extends React.PureComponent {
    componentDidMount() {
        const {
            loading,
            gridlyAccess,
            addGridlyAccount,
            fetchGridlyAccess,
            isGridlyGridsLoading,
            fetchGridlyDeliveredGrids,
            gridlyGrids,
            history,
            setDoneOAuthFlow
        } = this.props;

        const { flow_name, code } = queryParamsToObject(
            history.location.search
        );

        if (flow_name === 'GeneralOAuthFlow' && code) {
            addGridlyAccount({
                code,
                successCallback: () => {
                    setDoneOAuthFlow(true);
                    history.push('/orders');
                }
            });
        } else {
            if (!loading && !gridlyAccess) {
                fetchGridlyAccess();
            }

            if (!isGridlyGridsLoading && !gridlyGrids) {
                fetchGridlyDeliveredGrids();
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            isGridlyGridsLoading,
            gridlyGrids,
            fetchGridlyDeliveredGrids
        } = this.props;

        if (!isGridlyGridsLoading && !gridlyGrids) {
            fetchGridlyDeliveredGrids();
        }
    }

    onSyncGridly = () => {
        gotoGridlyOAuthPage();
    };

    onCleanMore = () => {
        window.open('https://www.gridly.com/');
    };

    onClose = () => {
        const { setMinIntegrationBox } = this.props;

        setMinIntegrationBox(true);
    };

    render() {
        const { gridlyAccess, loading, isMinIntegrationBox } = this.props;

        return (
            !isMinIntegrationBox &&
            !gridlyAccess &&
            !loading && (
                <div className="gridly-integrator-box">
                    <div className="mb-4 gridly-logo">
                        <img
                            className="logo"
                            title="Gridly"
                            alt="logo"
                            src="/img/logo_gridly.svg"
                        />
                    </div>
                    <img
                        className="close-icon clickable"
                        title="Close"
                        alt="icon"
                        src="/img/ic_close.svg"
                        onClick={this.onClose}
                    />
                    <div className="row">
                        <div className="col-md-6 text">
                            <div className="text">
                                {
                                    'Manage your game’s data as a single source of truth and roll out continuous updates with full localization support & version control. Start integrating with Gridly now!'
                                }
                            </div>
                            <div className="mt-4">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={this.onSyncGridly}
                                >
                                    Integrate
                                </button>

                                <button
                                    type="button"
                                    className="btn btn-secondary ml-3"
                                    onClick={this.onCleanMore}
                                >
                                    Learn More
                                </button>
                            </div>
                        </div>
                        <div className="col-md-6 relative">
                            <span className="gridly-demo">
                                <img
                                    title="Gridly feature"
                                    alt="logo"
                                    src="/img/gridly-demo.svg"
                                />
                                <img
                                    className="image"
                                    title="Gridly-person"
                                    alt="logo"
                                    src="/img/gridly-int-person.svg"
                                />
                            </span>
                        </div>
                    </div>
                </div>
            )
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        loading: state.getIn(['customer', 'gridly', 'access', 'loading']),
        isMinIntegrationBox: state.getIn([
            'customer',
            'gridly',
            'isMinIntegrationBox'
        ]),
        isGridlyGridsLoading: state.getIn([
            'customer',
            'gridly',
            'grids',
            'loading'
        ]),
        gridlyGrids: state.getIn(['customer', 'gridly', 'grids', 'data'])
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            addGridlyAccount,
            fetchGridlyAccess,
            fetchGridlyDeliveredGrids,
            setMinIntegrationBox,
            setDoneOAuthFlow
        },
        dispatch
    );
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(GridlyIntegrator)
);
