import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { object } from 'prop-types';
import { deliverToGridly } from './actions';
import { GRIDLY_APP_URL } from 'common/const';

class GridlyDeliver extends React.PureComponent {
    static propTypes = {
        order: object
    };

    state = {
        isLoading: false
    };

    onDeliver = () => {
        const { order, deliverToGridly } = this.props;
        const { isLoading } = this.state;

        if (isLoading) {
            return;
        }

        deliverToGridly(order);

        this.setState({
            isLoading: true
        });

        setTimeout(() => {
            this.setState({
                isLoading: false
            });
        }, 3000); // Max wait 3s for deliverToGridly job in the background
    };

    render() {
        const { isLoading } = this.state;
        const {
            projectId,
            dbId,
            gridlyDeliveredGrids,
            order,
            gridlyAccess
        } = this.props;

        const deliveredGrid =
            gridlyDeliveredGrids &&
            gridlyDeliveredGrids.find(grid => {
                return grid.orderUid === order.uid;
            });

        if (!gridlyAccess) {
            return <span />;
        }

        return (
            <div className="gridly-deliver">
                <span
                    title="Push to Gridly"
                    onClick={this.onDeliver}
                    className={`${isLoading ? 'disabled' : 'clickable'}`}
                >
                    <img
                        className="logo"
                        title=""
                        alt="logo"
                        src="/img/ic_gridly.svg"
                    />
                    &nbsp;&nbsp;
                    <span className="file-download">Push</span>
                </span>
                <span className="link-pos">
                    {projectId &&
                        deliveredGrid &&
                        deliveredGrid.viewId &&
                        !isLoading && (
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${GRIDLY_APP_URL}/projects/${projectId}/databases/${dbId}/grids/${deliveredGrid.gridId}/branches/${deliveredGrid.gridId}/views/${deliveredGrid.viewId}`}
                                className="file-download"
                            >
                                <img
                                    className="image"
                                    title="Open Grid"
                                    alt="open Grid"
                                    src="/img/external-link.svg"
                                />
                            </a>
                        )}
                    {isLoading && (
                        <i className="fa fa-spinner fa-pulse fa-1x fa-fw" />
                    )}
                </span>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const gridlyAccess = state.getIn(['customer', 'gridly', 'access', 'data']);

    return {
        gridlyAccess,
        projectId: gridlyAccess && gridlyAccess.projectId,
        dbId: gridlyAccess && gridlyAccess.dbId,
        gridlyDeliveredGrids: state.getIn([
            'customer',
            'gridly',
            'grids',
            'data'
        ])
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            deliverToGridly
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(GridlyDeliver);
