import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { func, array, bool, number } from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import { Alert, Spinner, toJS } from 'components';
import { fetchOrderList, closeMessage, downloadMergedFile } from './actions';
import OrderTable from './OrderTable';
import Pagination from './Pagination';
import GridlyIntegrator from './GridlyIntegrator';
import GridlyIntegrated from './GridlyIntegrated';

import './OrderList-style.css';

class OrderList extends React.PureComponent {
    static propTypes = {
        data: array,
        fetchData: func,
        loading: bool,
        total: number
    };

    constructor(props) {
        super(props);

        window.onbeforeunload = e => {
            localStorage.removeItem('currentPage');
        };

        this.state = {
            offset: 0,
            limit: 10,
            currentPage: Number(localStorage.getItem('currentPage') || 1)
        };
    }

    onContinueOrder = () => {
        window.location = '/';
    };

    componentDidMount() {
        const { loading, fetchData } = this.props;
        const { limit, currentPage } = this.state;
        this.setCurrentPage(currentPage);
        if (!loading) {
            fetchData({ offset: (currentPage - 1) * limit, limit });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { error, fetchData } = this.props;
        const { offset, limit, currentPage } = this.state;

        if (!error && !!prevProps.error) {
            fetchData({ offset, limit });
        }

        if (prevState.currentPage !== currentPage) {
            fetchData({ offset: (currentPage - 1) * limit, limit });
            this.setCurrentPage(currentPage);
        }
    }

    setCurrentPage = number => {
        localStorage.setItem('currentPage', number);
    };

    handleNextClicked = () => {
        this.setState({ currentPage: this.state.currentPage + 1 });
    };
    handlePreviousClicked = () => {
        this.setState({ currentPage: this.state.currentPage - 1 });
    };
    handlePageClicked = index => {
        this.setState({ currentPage: index });
    };

    render() {
        const {
            loading,
            data,
            closeMessage,
            error,
            downloadMergedFile,
            total,
            gridlyAccess
        } = this.props;

        const { limit, currentPage } = this.state;
        const pages = Math.floor(total / limit) + 1;

        if (error) {
            return (
                <Alert
                    type="danger"
                    title="Oops! Something went wrong"
                    message={`We encountered an unexpected problem. Contact our Customer Service to get support at <a href="mailto:sales@localizedirect.com" target="_blank">sales@localizedirect.com</a>.`}
                    onClose={closeMessage}
                    className="error-alert error-message"
                />
            );
        }

        if (loading || !data) {
            return <Spinner />;
        }

        return (
            <div className="box content-padding order-list container-full">
                <GridlyIntegrated />
                <div className="mb-4">
                    <GridlyIntegrator gridlyAccess={gridlyAccess} />
                </div>
                <div className="page-title row">
                    <span className="col-md-6">Order List</span>
                    <span className="col-md-6">
                        <Link
                            className="btn btn-primary btn-qna-continue-order"
                            to={`/`}
                        >
                            Continue Order
                        </Link>
                    </span>
                </div>
                <div className="d-flex mt-4 mb-1">
                    <div className="d-flex mt-3">
                        <Pagination
                            totalPages={pages}
                            currentPage={currentPage}
                            handleNextClicked={this.handleNextClicked}
                            handlePreviousClicked={this.handlePreviousClicked}
                            handlePageClicked={this.handlePageClicked}
                        />
                    </div>
                </div>
                <div>
                    <OrderTable
                        data={data}
                        downloadMergedFile={downloadMergedFile}
                        gridlyAccess={gridlyAccess}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        loading: state.getIn(['customer', 'orders', 'loading']),
        data: state.getIn(['customer', 'orders', 'data']),
        error: state.getIn(['customer', 'error']),
        total: state.getIn(['customer', 'orders', 'total']),
        gridlyAccess: state.getIn(['customer', 'gridly', 'access', 'data'])
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            fetchData: fetchOrderList,
            closeMessage,
            downloadMergedFile
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(toJS(withRouter(OrderList)));
